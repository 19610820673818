import { Button } from 'antd'
import React from 'react'
import { useDrawerContent } from '../useDrawerContent'

export default function CustomScoreTrigger() {
  const { setCustomMarkerScoreVisible: setVisible } = useDrawerContent()
  return (
    <div>
      <Button onClick={() => setVisible(true)}>自定义分数</Button>
    </div>
  )
}
