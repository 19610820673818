import classnames from 'classnames'
import React, { FC } from 'react'
import CustomMarkerScoreDrawer from '../custom-score/Drawer'
import '../style.less'
import { useDrawerContent } from '../useDrawerContent'
import { ColorToolbar } from './ColorToolbar'
import { DefaultToolbar } from './DefaultToolbar'
import { ScoreToolbar } from './ScoreToolbar'

export const FontSize = [16, 24, 32, 48]
export const StrokeSize = [2, 4, 8, 16]

export const Toolbar: FC<{
  onUndo: () => void
  onRedo: () => void
  onSave: () => void
  onRotate: (dir: 'l' | 'r') => void
  maxScore?: number
  layout?: 'page' | 'modal'
}> = props => {
  const { onUndo, onRedo, onSave, onRotate, maxScore } = props

  const {
    toolMode,
    setToolMode,
    resetToolMode,

    color,
    setColor,
    fontSize,
    setFontSize,
    panSize,
    setPanSize
  } = useDrawerContent()

  return (
    <div
      className={classnames({
        'drawer-toolbar': true,
        'page-mode': props.layout === 'page'
      })}
    >
      {[
        'default',
        'marker-right',
        'marker-wrong',
        'marker-half-right'
      ].includes(toolMode) && (
        <DefaultToolbar
          layout={props.layout}
          active={toolMode}
          onPenToolClick={() => setToolMode('pan')}
          onLineToolClick={() => setToolMode('line')}
          onTextToolClick={() => setToolMode('text')}
          onRotateToolClick={onRotate}
          onCropToolClick={() => setToolMode('crop')}
          onScoreMarkClick={() => setToolMode('score')}
          onMarkerRightClick={() => setToolMode('marker-right')}
          onMarkerWrongClick={() => setToolMode('marker-wrong')}
          onMarkerHalfRightClick={() => setToolMode('marker-half-right')}
          onUndoClick={onUndo}
          onRedoClick={onRedo}
          onSaveClick={onSave}
        />
      )}
      {['pan', 'line'].includes(toolMode) && (
        <ColorToolbar
          sizeMode='stroke'
          color={color}
          size={panSize}
          onColorChange={setColor}
          onSizeChange={setPanSize}
          onClose={resetToolMode}
        />
      )}
      {toolMode === 'text' && (
        <ColorToolbar
          sizeMode='font'
          color={color}
          size={fontSize}
          onColorChange={setColor}
          onSizeChange={setFontSize}
          onClose={resetToolMode}
        />
      )}
      {toolMode === 'score' && <ScoreToolbar maxScore={maxScore} />}

      <CustomMarkerScoreDrawer />
    </div>
  )
}
