import { APP_SCOPE } from 'config'
import { POST } from 'core/request'
import { createEdit, createList } from 'core/service'
import _ from 'lodash'
import moment, { Moment } from 'moment'
import { Paper } from 'typing'

const { ListProvider, useListContext } = createList<
  Paper.Paper,
  { exam_id: string }
>({
  url: () => '/city/exam/paper'
})

const { EditProvider, useEditContext } = createEdit<Paper.Paper>({
  submit: {
    url: '/city/exam/paper',
    parser: data => {
      const t1 = data._timeRange[0]
      const t2 = data._timeRange[1]
      const setTimeAndFormat = (val: Moment, time: Moment) => {
        return moment(val)
          .set({
            hour: time.hour(),
            minute: time.minute(),
            second: time.second()
          })
          .format(`YYYY-MM-DD HH:mm:ss`)
      }
      return {
        ..._.omit(data, ['_date', '_timeRange']),
        begin_time: setTimeAndFormat(data._date, t1),
        end_time: setTimeAndFormat(data._date, t2)
      } as Paper.Paper
    }
  },
  remove: {
    url: params => `/city/exam/paper/${params.id}`
  }
})

const {
  EditProvider: MarkingSettingEditProvider,
  useEditContext: useMarkingSettingEditContext
} = createEdit<Paper.PaperMarkingSettingFormData, Paper.Paper>({
  fetch: {
    url: params =>
      `/${APP_SCOPE.isInFanYa ? 'fanya' : 'city'}/exam/paper/${
        params.id
      }/setting`,
    parser: data => {
      return {
        ...data,
        review_mode: data.review_mode || 1,
        review_time: data.review_time ? moment(data.review_time) : null,
        review_range: data.review_range || null,
        review_end_time: data.review_end_time
          ? moment(data.review_end_time)
          : null,

        distribute_by_real: data.distribute_by_real || 0,

        // Pick value from filter object
        judge_filter_in_percent:
          _.find(data.judge_filter, { type: 2 })?.value * 100 || null,
        score_plus_mode: data.score_plus_mode ?? 0,
        score_auto_count: data.score_auto_count ?? 0,

        change_score: data.change_score ?? 1,

        judge_filter_by_question: data.judge_filter_by_question || [],

        question_type2_group_marking:
          data.question_type2_group_marking ?? false,

        allow_teacher_supplement: data.allow_teacher_supplement ?? 0,

        judge_teacher_by_question: data.judge_teacher_by_question || []
      }
    }
  },
  submit: {
    url: ({ params }) =>
      `/${APP_SCOPE.isInFanYa ? 'fanya' : 'city'}/exam/paper/${
        params.id
      }/setting`,
    method: POST
  }
})

export {
  EditProvider,
  ListProvider,
  MarkingSettingEditProvider,
  useEditContext,
  useListContext,
  useMarkingSettingEditContext
}
