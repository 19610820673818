import { APP_SCOPE, IS_DEV } from 'config'
import moment from 'moment'
import 'moment/locale/zh-cn'
import qs from 'qs'
import React from 'react'
import 'react-app-polyfill/ie11'
import ReactDOM from 'react-dom'
import { App } from './App'
import './global.less'

moment.locale('zh-cn')

if (IS_DEV) {
  localStorage.debug = '*,-sock*,-mocha*,-cypress*'
}

const query = qs.parse(window.location.search, {
  ignoreQueryPrefix: true
})
console.log('query', query)

if (query && query.token) {
  localStorage.setItem('token', query.token)
}
if (query) {
  APP_SCOPE.isInFanYa = query.source === 'kaoshi'
  APP_SCOPE.redirectUrl = query.backurl
}
console.log(APP_SCOPE)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
