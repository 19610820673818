import { Drawer } from 'antd'
import React, { PropsWithChildren } from 'react'
import { useDrawerContent } from '../useDrawerContent'
import Content from './Content'

export default function (props: PropsWithChildren<{}>) {
  const {
    customMarkerScoreVisible: visible,
    setCustomMarkerScoreVisible: setVisible
  } = useDrawerContent()
  return (
    <Drawer
      visible={visible}
      onClose={() => setVisible(false)}
      title='自定义批注分数'
      width={350}
      placement='right'
      destroyOnClose
    >
      <div style={{ position: 'relative', height: '100%', overflowY: 'auto' }}>
        <Content />
      </div>
    </Drawer>
  )
}
