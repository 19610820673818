import { EditOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Form,
  InputNumber,
  Modal,
  Space,
  Table,
  message
} from 'antd'
import { ModalSizeNormal } from 'config'
import { GET, POST, defaultErrorHandler } from 'core/request'
import _ from 'lodash'
import { useMarkingSettingEditContext } from 'pages/exam-papers/store'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useList } from 'react-use'
import { Teacher } from 'typing.exam'
import { TeacherSelectDialogForFanya } from '../TeacherSelect/DialogForFanya'
import { useRedistributeContext } from '../useRedistribute'

export type RedistributeTeacherListItem = {
  exam_paper_id: number
  teacher_uid: number
  display_name: string
  count: number
  complete_count: number
  un_complete_count: number
}

export const RedistributeDialog: FC = () => {
  const edit = useMarkingSettingEditContext()

  // Modal
  const {
    visible,
    setVisible,
    bindingGroup,
    teacherGroupIndex
  } = useRedistributeContext()

  // List
  const [list, { set: setList, push: pushListItem }] = useList<
    RedistributeTeacherListItem
  >([])
  const [total, setTotal] = useState<{
    exam_paper_id: number
    total_count: number
    total_complete_count: number
    total_un_complete_count: number
  }>()

  const fetch = useCallback(
    async function () {
      const teachers = (() => {
        if (_.isNumber(teacherGroupIndex)) {
          return bindingGroup?.groupedTeachers?.[teacherGroupIndex]
        }
        return bindingGroup?.teachers
      })()
      const { data } = await GET('/fanya/exam/paper/review_progress', {
        data: {
          exam_paper_id: edit.data.id,
          teachers: teachers?.map(t => t.uid).join(','),
          quest_group: bindingGroup?.questions?.map(q => q.id).join(',')
        }
      })
      setList(data.teacher)
      setTotal(data.total)
    },
    [edit.data.id, bindingGroup, teacherGroupIndex, setList]
  )

  useEffect(() => {
    visible && fetch()
  }, [visible, fetch])

  // Form
  type TeacherUid = string
  const [form] = Form.useForm()
  const [formData, setFormData] = useState<Record<TeacherUid, number>>({})
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    console.log(formData)
  }, [formData])

  // Init
  useEffect(() => {
    const data = list.reduce((acc, item) => {
      acc[item.teacher_uid] = item.un_complete_count
      return acc
    }, {})
    setFormData(data)
    form.setFieldsValue(data)
  }, [list, form])

  // Update formData on fields change
  const onChange = useCallback(
    (val: number, teacherUid: number, index) => {
      const _formData = formData
      _formData[teacherUid] = val

      if (total && index !== list.length - 1) {
        const sum = Object.values(formData).reduce((acc, val) => acc + val, 0)
        const diff = total?.total_un_complete_count - sum
        const lastItem = list[list.length - 1]
        const newCount = Math.max(0, _formData[lastItem.teacher_uid] + diff)
        _formData[lastItem.teacher_uid] = newCount
      }
      setFormData(_formData)
      form.setFieldsValue(_formData)
    },
    [form, formData, list, total]
  )

  const validate = useCallback(() => {
    const sum = Object.values(formData).reduce((acc, val) => acc + val, 0)
    if (sum !== total?.total_un_complete_count) {
      Modal.error({
        title: '分配数与待批阅总数不一致',
        content: `当前分配: ${sum}, 待批阅: ${total?.total_un_complete_count}`
      })
      return false
    }
    return true
  }, [formData, total])

  const submit = useCallback(
    async function () {
      const quest_group = {
        quests: bindingGroup?.questions?.map(q => q.id),
        teachers: (() => {
          if (_.isNumber(teacherGroupIndex)) {
            return bindingGroup?.groupedTeachers?.map(group =>
              group.map(t => t.uid)
            )
          }
          return bindingGroup?.teachers?.map(t => t.uid)
        })()
      }
      const teachers = formData
      await POST(`/fanya/exam/paper/${edit.data.id}/supplement/setting`, {
        data: {
          quest_group,
          teachers,
          new_add_teacher: _.uniqBy(addedTeachers.current, 'uid')
        }
      })
    },
    [formData, bindingGroup, edit.data.id]
  )

  const onSubmit = useCallback(
    async function () {
      if (saving) return

      let hide
      try {
        setSaving(true)

        await form.validateFields()

        if (!validate()) return

        hide = message.loading('正在保存', 0)
        await submit()
        hide()
        message.success('保存成功')

        setTimeout(() => {
          location.reload()
        }, 1500)
      } catch (e) {
        hide()
        defaultErrorHandler(e as any)
      } finally {
        setSaving(false)
      }
    },
    [form, validate, submit, saving]
  )

  const onDealClick = useCallback(() => {
    const result = deal(total?.total_un_complete_count || 0, list.length)

    const data = { ...formData }

    result.forEach((val, index) => {
      const teacherUid = list[index].teacher_uid
      data[teacherUid] = val
    })

    setFormData(data)
    form.setFieldsValue(data)

    message.success('已平均分配')
  }, [total, list, formData, form])

  function deal(total: number, bucket: number): number[] {
    const result = Array.from({ length: bucket }, () => 0)
    for (let i = 0; i < total; i++) {
      result[i % bucket]++
    }
    return result
  }

  // TeacherSelectDialog
  const [teacherSelectVisible, setTeacherSelectVisible] = useState(false)

  const addedTeachers = useRef<Teacher[]>([])

  const onAddTeacher = useCallback(
    (data: Teacher[]) => {
      setTeacherSelectVisible(false)
      const val = data[0]
      if (!val) return
      if (list.find(it => it.teacher_uid === val.uid)) return

      setFormData({
        ...formData,
        [val.uid]: 0
      })
      pushListItem({
        exam_paper_id: edit.data.id,
        teacher_uid: val.uid,
        display_name: val.display_name,
        count: 0,
        complete_count: 0,
        un_complete_count: 0
      })
      addedTeachers.current.push(val)
    },
    [formData, pushListItem, edit.data.id, list]
  )

  return (
    <Modal
      title='重新分配'
      visible={visible}
      width={ModalSizeNormal}
      onCancel={() => setVisible(false)}
      onOk={onSubmit}
      okButtonProps={{ loading: saving }}
    >
      <Alert
        message={
          <Space>
            <span>
              该题组已批阅试卷数:{' '}
              <span className='primary'>{total?.total_complete_count}</span>
            </span>
            /
            <span>
              待批阅试卷数:{' '}
              <span className='primary'>{total?.total_un_complete_count}</span>
            </span>
          </Space>
        }
        type='info'
        style={{ marginBottom: 20 }}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 10
        }}
      >
        <Button
          type='primary'
          size='small'
          onClick={() => setTeacherSelectVisible(true)}
        >
          添加教师
        </Button>
        <Button size='small' icon={<EditOutlined />} onClick={onDealClick}>
          平均分配
        </Button>
      </div>

      <Form form={form}>
        <Table<RedistributeTeacherListItem>
          dataSource={list}
          pagination={false}
          columns={[
            {
              title: '序号',
              key: 'index',
              render: (text, row, index) => index + 1,
              width: '5em'
            },
            {
              title: '教师',
              dataIndex: 'display_name'
            },
            {
              title: '工号',
              dataIndex: 'name'
            },
            {
              title: '总数',
              dataIndex: 'count',
              align: 'right'
            },
            {
              title: '已批阅',
              dataIndex: 'complete_count',
              align: 'right'
            },
            {
              title: '待批阅',
              align: 'right',
              render: (text, row, index) => {
                return (
                  <Form.Item
                    name={`${row.teacher_uid}`}
                    rules={[
                      {
                        required: true,
                        type: 'integer',
                        message: '请输入整数'
                      }
                    ]}
                    style={{ margin: 0 }}
                  >
                    <InputNumber
                      min={0}
                      max={total?.total_un_complete_count}
                      onChange={val =>
                        onChange(val as number, row.teacher_uid, index)
                      }
                    />
                  </Form.Item>
                )
              }
            }
          ]}
        ></Table>
      </Form>

      <TeacherSelectDialogForFanya
        visible={teacherSelectVisible}
        title={'选择教师'}
        multi={false}
        onConfirm={onAddTeacher}
        onCancel={() => setTeacherSelectVisible(false)}
      />
    </Modal>
  )
}
