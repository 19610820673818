import { Spin } from 'antd'
import { ImageViewer } from 'components/ImageViewer'
import $ from 'jquery'
import React, { FC, useEffect, useState } from 'react'
import {
  adaptImageBaseUrlOfContent,
  isAttachIframe,
  isAttachNew,
  isAttachUEUpload,
  isIFrameAudioContent,
  removeAttach,
  transAttach,
  transAttachIframe,
  transIframeAudio
} from 'utils'
import { isRuntimeCXStudy } from '../../config'
import { useMarkingForm } from '../../pages/marking-form/useMarkingForm'
import { CXStudyPreviewImage } from '../../utils/runtime-study'

export interface RichTextProps {
  html?: string
  className?: string
  emptyText?: string
  onClickHandler?: (el: JQuery) => void
  onImageClick?: (el: JQuery) => void
}

const log = require('debug')

const EMPTY_DEFAULT = '未作答'

export const RichText: FC<RichTextProps> = props => {
  const EMPTY = `[${props.emptyText || EMPTY_DEFAULT}]`

  const { markingPaper } = useMarkingForm()

  const [imageViewerUrl, setImageViewerUrl] = useState<string | null>(null)

  // View
  const [html, setHtml] = useState<string>()
  const [loading, setLoading] = useState(false)

  function needsTransHTML(html) {
    return (
      isIFrameAudioContent(html) ||
      isAttachUEUpload(html) ||
      isAttachNew(html) ||
      isAttachIframe(html)
    )
  }

  async function transformHTML(html: string) {
    setLoading(true)
    const hideInfo = markingPaper?.hide_info
    try {
      let res: string
      res = await transIframeAudio(html, hideInfo)
      res = await transAttach(res, hideInfo)
      res = transAttachIframe(res, hideInfo)
      setHTMLWithoutAttach(res)
    } catch (e) {
      setHTMLWithoutAttach(html)
    } finally {
      setLoading(false)
    }
  }

  function setHTMLWithoutAttach(val: string) {
    setHtml(autoHttpProtocol(removeAttach(val)))
  }

  // replace all img src in html, from `http://` `https://` to `//`
  function autoHttpProtocol(html: string) {
    return html.replace(/(src=")https?:/g, '$1')
  }

  useEffect(() => {
    if (!props.html) return

    const html = adaptImageBaseUrlOfContent(props.html)
    if (needsTransHTML(html)) {
      transformHTML(html)
    } else {
      setHTMLWithoutAttach(html)
    }
    // log('RichText:')('re-render', props.html)
  }, [props.html])

  // Event
  function onClickHandler(e: React.MouseEvent) {
    const el = $(e.target)
    if (el.is('img')) {
      const src = el.attr('src')
      if (!src) {
        return
      }

      if (isRuntimeCXStudy) {
        CXStudyPreviewImage([src])
        return
      }

      if (props.onImageClick) {
        props.onImageClick(el as JQuery)
      } else {
        setImageViewerUrl(src)
      }
    } else {
      props.onClickHandler && props.onClickHandler(el as JQuery)
    }
  }

  if (!html || !html.trim()) {
    if (loading) {
      return <Spin size={'small'} />
    } else {
      return (
        <div
          className={`rich-text danger`}
          dangerouslySetInnerHTML={{ __html: EMPTY }}
        />
      )
    }
  }

  return (
    <>
      <div
        className={`rich-text ${props.className || ''}`}
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={e => onClickHandler(e)}
      />
      {imageViewerUrl && (
        <ImageViewer
          url={imageViewerUrl}
          onClose={() => setImageViewerUrl(null)}
        />
      )}
    </>
  )
}
