import { DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Alert, Button, InputNumber } from 'antd'
import _ from 'lodash'
import React, { CSSProperties, FC, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { MarkerScoreRecords, useDrawerContent } from '../useDrawerContent'

let id = 0

export default function Content() {
  const sensors = useSensors(useSensor(PointerSensor))

  const {
    customMarkerScore: items,
    setCustomMarkerScore: setItems
  } = useDrawerContent()

  const [activeId, setActiveId] = useState(null)

  function onDragStart(event) {
    console.log('Drag started:', event.active.id)
    setActiveId(event.active.id)
  }

  function onDragEnd(event) {
    console.log('Drag ended:', event.active.id)
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id)
      const newIndex = items.findIndex(item => item.id === over.id)

      setItems(arrayMove(items, oldIndex, newIndex))
    }
    setActiveId(null)
  }

  function onDragCancel() {
    setActiveId(null)
  }

  function handleRemove(id) {
    setItems(items.filter(item => item.id !== id))
  }

  function handleAdd() {
    // return if some value of item is null
    if (_.some(items, item => !_.isNumber(item.value))) {
      return
    }

    const maxValue = _.maxBy(items, 'value')?.value || 0
    const newItem = {
      id: uuidv4(),
      value: maxValue + 1,
      custom: true
    }
    setItems([newItem, ...items])
  }

  function handleChange(id, value) {
    setItems(items.map(item => (item.id === id ? { ...item, value } : item)))
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <Alert
        message='可拖拽进行排序，仅显示前7个分数'
        style={{ marginBottom: 16 }}
      />

      <div style={{ marginBottom: 16, textAlign: 'right' }}>
        <Button type='primary' onClick={handleAdd}>
          添加
        </Button>
      </div>

      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <Item
            key={index}
            item={item}
            onRemove={handleRemove}
            onChange={value => handleChange(item.id, value)}
          ></Item>
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <Item item={items.find(item => item.id === activeId)!} />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

const Item: FC<{
  item: MarkerScoreRecords[number]
  onChange?: (value: number | null) => void
  onRemove?: (id: string) => void
}> = props => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({ id: props.item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    userSelect: 'none',
    border: '1px solid #ccc',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    borderRadius: 4
  } as CSSProperties

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div
        style={{
          marginRight: 24,
          flex: '0 0 auto',
          color: 'rgba(0,0,0,0.5)',
          cursor: isDragging ? 'grabbing' : 'grab'
        }}
      >
        <MenuOutlined {...listeners} />
      </div>
      <div style={{ flex: '1 1 auto' }}>
        {props.item.custom ? (
          <>
            <InputNumber
              disabled={isDragging || props.item.disabled}
              min={0}
              max={100}
              value={props.item.value}
              onChange={value => {
                props.onChange?.(_.isNumber(value) ? value : null)
              }}
              style={{ width: 140 }}
            />
          </>
        ) : (
          <span>{props.item.value}</span>
        )}
      </div>
      <div>
        {props.item.custom && (
          <Button
            type='link'
            danger
            size='small'
            onClick={() => props.onRemove?.(props.item.id)}
            style={{ marginLeft: 8 }}
          >
            <DeleteOutlined />
          </Button>
        )}
      </div>
    </div>
  )
}
